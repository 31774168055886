import styled from "styled-components";
import {mediaMax} from "../../helpers/MediaQueries";

interface IStyleProps {
  mobHeight?: string;
  textAlign?: string;
  vertAlign?: string;
}
export const ContentNextPageWrap = styled.div<IStyleProps>`
  background-color: #f4f3ee;
  padding: 9.5vh 18% 7.5vh 13%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: relative;
  min-height: 78vh;
  ${mediaMax.tablet`
      padding: 0;
      min-height: ${(props:any) => props.mobHeight ? props.mobHeight : "30vh"};
      justify-content: ${(props:any) => props.textAlign ? props.textAlign : "center"};
      align-items: ${(props:any) => props.vertAlign ? props.vertAlign : "center"};
      padding: 10vh 5.3%;
  `}
  a {
    display: inline-flex;
    justify-content: flex-end;
    align-items: flex-end;
    flex-direction: column;
    svg {
      ${mediaMax.tablet`
        max-width: 18px;
        max-height: 40px;
      `}
    }
  }
  span {
    color: #d5d2c5;
    text-transform: uppercase;
    ${mediaMax.phoneXL`
      font-size: 40px;
    `}
  }
`;
