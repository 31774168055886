import React, { FC, useEffect, useRef } from "react";
import { Link } from "gatsby";
import { ContentNextPageWrap } from "./styled.index";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import gsap from "gsap";

interface IContentText {
  nextTitle: React.ReactFragment;
  nextLink: string;
  type?: string;
  mobHeight?: string;
  textAlign?: string;
  vertAlign?: string;
}

const ContentNextPage: FC<IContentText> = ({ nextTitle, nextLink, type, mobHeight, textAlign , vertAlign}) => {
  const contentWrapRef = useRef<HTMLDivElement>(null);
  const linkRef = useRef<any>(null);

  useEffect(() => {
    ScrollTrigger.create({
      trigger: contentWrapRef?.current || "",
      toggleActions: "play none none none",
      start: "top+=25% bottom",
      end: "bottom top",
      animation: gsap
        .timeline()
        .fromTo(
          contentWrapRef?.current || null,
          1.25,
          { opacity: 0 },
          { opacity: 1 },
          "+=.1"
        )
        .fromTo(
          linkRef?.current || null,
          .75,
          { opacity: 0, y: 35 },
          { opacity: 1, y: 0 },
          "-=.75"
        ),
    });
  }, []);

  return (
    <ContentNextPageWrap className={type} ref={contentWrapRef} mobHeight={mobHeight} textAlign={textAlign} vertAlign={vertAlign}>
      <Link to={nextLink} ref={linkRef}>
        <span className={"fs-xxl"}>{nextTitle}</span>
        <svg
          width="22"
          height="70"
          viewBox="0 0 15 27"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M1.36035 1.5L13.3027 13.4424L10.8145 15.9302L1.36035 25.3843"
            stroke="#D5D2C5"
            strokeWidth="1.5"
            strokeLinecap="round"
          />
        </svg>
      </Link>
    </ContentNextPageWrap>
  );
};
export default ContentNextPage;
