import React, {FC, useContext, useEffect, useRef, useState} from "react";
import {Link} from "gatsby";
import film_ff from "../../assets/images/film_ff.jpg"
import {
  VideoStyled,
  VideoWrap,
  IntroContent,
  PageHeading,
  BackgroundImage,
  LogoStyled
} from "./index.styled";
import IntroLogo from "../../assets/images/intro-logo.png";
import IntroArrowDown from "../../assets/images/intro-arrow-down.png";
import FilmArrowRight from "../../assets/images/arrow_right2.svg";
import {ScrollTrigger} from "gsap/ScrollTrigger";
import gsap from "gsap";
import useWindowSize from "../../helpers/UseWindowSize";

interface IProps {
  page: {
    videoSrc?: string;
    title?: string | boolean;
    subtitle?: string | boolean;
    positioning?: string;
    fadeTime?: boolean;
    logoFade?: boolean;
    mobImgName?: string;
  };
}

const FilmVideo: FC<IProps> = ({page}) => {
  const ContentWrapRef = useRef<HTMLDivElement>(null);
  const pageHeadingRef = useRef<HTMLHeadingElement>(null);
  const introLogoRef = useRef<HTMLImageElement>(null);
  const videoRef = useRef<any>(null);
  const [playing, setPlaying] = useState(false)
  const {width} = useWindowSize();

  useEffect(() => {
    ScrollTrigger.create({
      trigger: ContentWrapRef?.current || "",
      toggleActions: "play none none none",
      start: "top+=25% bottom",
      animation: gsap
        .timeline()
        .fromTo(
          ContentWrapRef?.current || null,
          0.55,
          {opacity: 0},
          {opacity: 1},
          "+=.1"
        )
    });
  }, []);

  const handlePlayVideo = () => {
    setPlaying(true)
    setTimeout(() => {
      if (width <= 769) {
        ScrollTrigger.refresh()
        if (videoRef?.current?.requestFullscreen) {
          videoRef?.current?.requestFullscreen();
        } else if (videoRef?.current?.webkitRequestFullscreen) { /* Safari */
          videoRef?.current?.webkitRequestFullscreen();
        } else if (videoRef?.current?.msRequestFullscreen) { /* IE11 */
          videoRef?.current?.msRequestFullscreen();
        }
      }
    }, 2000)
  }

  return (
    <VideoWrap ref={ContentWrapRef} className={playing ? "height_auto" : ""}>
      {
        playing ?
          <VideoStyled
            controls={true}
            autoPlay={true}
            muted={false}
            loop={false}
            playsInline={true}
            poster={film_ff}
            ref={videoRef}
          >
            <source
              src={"https://dbox-static-files.web.app/pomp/The_Ritz-Carlton_Residences_Pompano_Beach_v11_1080p_fast.mp4"}
              type="video/mp4"/>
          </VideoStyled>
          :
          <IntroContent>
            <BackgroundImage src={film_ff}/>
            <Link to={"/"}>
              <LogoStyled src={IntroLogo} alt="The Ritz-Carlton Residences" ref={introLogoRef}/>
            </Link>
            <img className={'arrowDwn'} src={IntroArrowDown} alt="The Ritz-Carlton Residences"/>
            {page.title && (
              <>
                <PageHeading className={`${page.positioning}`} ref={pageHeadingRef} onClick={handlePlayVideo}>
                  <h1 className={"fs-xxl"}>{page.title}</h1>
                </PageHeading>
                <PageHeading className={"center"} onClick={handlePlayVideo}>
                  <p className={"fs-xl"}>
                    {page.subtitle}
                    <img src={FilmArrowRight} alt="arrow right"/>
                  </p>
                </PageHeading>
              </>
            )}
          </IntroContent>
      }
    </VideoWrap>
  );
};
export default FilmVideo;
