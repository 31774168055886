import styled from "styled-components";
import {mediaMax} from "../../helpers/MediaQueries";

export const VideoWrap = styled.div`
  position: relative;
  width: 100%;
  height: 100vh;
  max-height: -webkit-fill-available;
  overflow: hidden;
  z-index: 10;
  background: #fbf7f2;
  &.height_auto{
    ${mediaMax.tablet`
      height: auto;
    `};
  }
`;
export const ImageWrap = styled.div`
  position: absolute;
  width: 100%;
  height: 100vh;
  pointer-events: none;
  overflow: hidden;
  background: #fff;
`;

export const VideoStyled = styled.video`
  width: 100%;
  height: 100vh;
  overflow: hidden;
  background: #fff;
  transition: .6s ease-out;
  object-fit: cover;
  pointer-events: all;
  ${mediaMax.tablet`
    height: auto;
  `};
`;

export const IntroContent = styled.div`
  padding-top: 88px;
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  .arrowDwn {
    max-width: 280px;
    ${mediaMax.phoneXL`
      max-width: 265px;    
    `}
  }
  ${mediaMax.tablet`
    padding-top: 48px;
  `};
}
`;
export const LogoStyled = styled.img`
  cursor: pointer;
  max-width: 280px;
  ${mediaMax.tablet`
      max-width: 265px;
    `};
`;
export const BackgroundImage = styled.img`
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
`;
export const IconSound = styled.img`
  display: block !important;
  cursor: pointer;
  position: absolute;
  left: 40px;
  top: 36px;
  z-index: 2;
  ${mediaMax.phoneXL`
    right: 17px;
    top: 30px;
    max-width: 29px;
  `}
`;

export const PageHeading = styled.div`
  max-width: 600px;
  position: absolute;
  color: #fff;
  word-spacing: 100vh;
  text-transform: uppercase;
  transition: .5s ease-out;
  cursor: pointer;
  font-weight: 100;

  &.left {
    left: 6.2%;
    bottom: 11.1%;
  }
  &.center {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  p {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-right: 12px;

    img {
      height: 40px;
      margin-left: 24px;
      margin-bottom: 4px;
    }
  }

  ${mediaMax.tabletLandscape`
    font-size: 80px;
    text-align: center;
    line-height: .95;
    &.center, &.left, &.right {
      bottom: 140px;
      left: 50%;
      transform: translateX(-50%);
    }
    
  p {
    font-size: 24px;
    margin-right: 6px;
    img {
      height: 24px;
      margin-left: 10px;
    }
  }
  `};
  ${mediaMax.tablet`
    font-size: 60px;
    &.center, &.left, &.right {
      bottom: 100px;
    }
    p {
      font-size: 18px;
      margin-right: 4px;
      img {
        height: 18px;
        margin-left: 8px;
      }
    }
  `};
`;
