import React from "react";
import introVid from "../assets/videos/intro_video.mp4";
import introVidMob from "../assets/videos/intro_video_mob.mp4";
import beachAmVid from "../assets/videos/beach_amenities.mp4";
import beachAmVidMob from "../assets/videos/beach_amenities_mob.mp4";
import beachResVid from "../assets/videos/beach_res.mp4";
import beachResVidMob from "../assets/videos/beach_res_mob.mp4";
import marinaAmVid from "../assets/videos/marina_amenities.mp4";
import marinaAmVidMob from "../assets/videos/marina_amenities_mob.mp4";
import marinaResVid from "../assets/videos/marina_res.mp4";
import marinaResVidMob from "../assets/videos/marina_res_mob.mp4";

import introFB from "../assets/images/fallback-images/fb_intro_video.jpg";
import introFBMob from "../assets/images/fallback-images/fb_intro_video_mob.jpg";
import beachFBAm from "../assets/images/fallback-images/fb_beachTower.jpg";
import beachFBAmMob from "../assets/images/fallback-images/fb_beach_tower_mob.jpg";
import beachFBRes from "../assets/images/fallback-images/fb_beach_tower_res.jpg";
import beachFBResMob from "../assets/images/fallback-images/fb_beach_tower_res_mob.jpg";
import marinaFBRes from "../assets/images/fallback-images/fb_marina_tower_res.jpg";
import marinaFBResMob from "../assets/images/fallback-images/fb_marina_tower_res_mob.jpg";
import marinaFBAm from "../assets/images/fallback-images/fb_marinaTower.jpg";
import marinaFBAmMob from "../assets/images/fallback-images/fb_marina_tower_mob.jpg";


export const introData = {
  introduction: {
    title: "",
    positioning: "",
    videoSrc: introVid,
    videoSrcMob: introVidMob,
    fbImageName: introFB,
    fbImageNameMob: introFBMob,
  },
  beachTower: {
    title: "The Beach Tower",
    positioning: "left",
    videoSrc: beachAmVid,
    videoSrcMob: beachAmVidMob,
    imgName: "",
    fadeTime: true,
    fbImageName: beachFBAm,
    fbImageNameMob: beachFBAmMob,
  },
  beachTowerRes: {
    videoSrc: beachResVid,
    videoSrcMob: beachResVidMob,
    imgName: "",
    fbImageName: beachFBRes,
    fbImageNameMob: beachFBResMob,

  },

  marinaTower: {
    title: "The Marina Tower",
    positioning: "left",
    videoSrc: marinaAmVid,
    videoSrcMob: marinaAmVidMob,
    imgName: "",
    fadeTime: true,
    fbImageName: marinaFBAm,
    fbImageNameMob: marinaFBAmMob,
  },
  marinaTowerRes: {
    title: "",
    positioning: "",
    videoSrc: marinaResVid,
    videoSrcMob: marinaResVidMob,
    imgName: "",
    fbImageName: marinaFBRes,
    fbImageNameMob: marinaFBResMob,
  },
  team: {
    title: "Team",
    positioning: "left",
    videoSrc: "",
    imgName: "team_intro.jpg",
  },
  location: {
    title: "LOCATION",
    positioning: "left",
    videoSrc: "",
    imgName: "location_intro.jpg",
    mobImgName: "location_intro_mob.jpg",
    mobImgNameMob: "location_intro_mob_mob.jpg",
  },
  film: {
    title: "Film",
    subtitle: "Play",
    positioning: "left",
    videoSrc: 'https://vimeo.com/718706324',
    imgName: "",
    fadeTime: true,
    logoFade: true
  }
}
